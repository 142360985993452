import React, { useState, useEffect } from 'react';
import './dashboard.less';
import { maxBy } from 'lodash/math';
import { useHistory } from 'react-router-dom';
import {
    Col,
    Row,
    Button,
    Form,
    Select,
    Space,
    Card,
    Typography,
    DatePicker,
    Spin,
    Empty,
} from 'antd';
import Plot from 'react-plotly.js';
import locale from 'antd/es/date-picker/locale/ja_JP';
import CategorySelect from './CategorySelect';
import SwarmChart from './SwarmChart';
import { GRAVITY_API_ROOT } from '../../constants';
import { useParams } from 'react-router-dom';
import * as moment from 'moment';
import * as localeDictionary from 'plotly.js/lib/locales/ja.js'
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import { authenticationService } from '../../Utilities/authenticationService';

const Dashboard = () => {
    const [form] = Form.useForm();
    const { nameId } = useParams();
    const [selectedTalent, setSelectedTalent] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({})
    const [talents, setTalents] = useState([])
    const [selectedCommentsCategory, setSelectedCommentsCategory] = useState('');
    const [searchText, setSearchText] = useState('');
    const [checked, ] = useState(false);
    const history = useHistory();
    const [showSentiments, ] = useState(['positive', 'negative', 'others']);

    // Make sure this matches with constants.js commentTypes
    const sentimentTranslation = {
        'positive': 'ポジティブ',
        'negative': 'ネガティブ',
        'other': 'その他',
    }

    const savedFormParams = localStorage.dashboard && JSON.parse(localStorage.dashboard);

    useEffect(() => {
        if (data?.retweets_and_followers?.retweets) {
            const retweets = data?.retweets_and_followers?.retweets;
            const x = [];
            const y = [];
            const text = [];
            const sentiment_sign = [];
            const tokens = searchText.toLowerCase().split(/\s+/);

            retweets.text.forEach((retweet, index) => {
                const allTokensMatch = tokens.every((token) =>
                    retweet.toLowerCase().includes(token)
                );
                const sentiment = retweets.sentiment_sign[index];
                if (
                    allTokensMatch &&
                    !(checked && retweet.includes("RT")) &&
                    showSentiments.includes(sentiment)
                ) {
                    text.push(retweet);
                    x.push(retweets.x[index]);
                    y.push(retweets.y[index]);
                    sentiment_sign.push(retweets.sentiment_sign[index])
                }
            });
        }
    }, [data, searchText, checked, showSentiments]);

    const defaultTalent = () => {
        if (nameId) {
            return JSON.stringify(selectedTalent)
        } else if (savedFormParams) {
            return savedFormParams.talent;
        }
    };

    const defaultDates = () => {
        if (savedFormParams && savedFormParams.date && savedFormParams.date.length >= 2) {
            return [moment(savedFormParams.date[0]).startOf('day'), moment(savedFormParams.date[1]).startOf('day')];
        } else {
            return [moment().startOf('day').subtract(1, 'months'), moment().startOf('day')];
        }
    };

    const [selectedDate, setSelectedDate] = useState(defaultDates());

    useEffect(() => {
        if (selectedDate !== '' && selectedTalent !== '') {
            setLoading(true);
            fetch(`${GRAVITY_API_ROOT}/dashboard?talent=${selectedTalent.id}&date=${selectedDate}`, {
                headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${authenticationService.currentUserValue.token}`
            }
            }).then(response => response.json()).then(reader => {
                setLoading(false);
                setData(reader);
                setSearchText('')
                reader.beeswarm_chart && Object.entries(reader.beeswarm_chart).length > 0 && setSelectedCommentsCategory(
                    reader.beeswarm_chart && maxBy(Object.entries(reader.beeswarm_chart), type => type[1].length)[0]
                );
            })
        }
    }, [selectedTalent, selectedDate])

    useEffect(() => {
        fetch(`${GRAVITY_API_ROOT}/names`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${authenticationService.currentUserValue.token}`
            }
        }).then(response => response.json()).then(reader => {
            if (nameId) {
                const preSelectedTalent = reader.find(nameAndId => nameAndId[1] === parseInt(nameId));
                setSelectedTalent({ name: preSelectedTalent[0], id: preSelectedTalent[1] });
            }
            setTalents(reader.map(nameAndId => ({ name: nameAndId[0], id: nameAndId[1] })));
        });
    }, [nameId, talents.length])

    if (nameId && selectedTalent === '') { return <Spin /> }

    return <>
        <Space style={{ width: '100%' }} direction='vertical'>
            <Row>
                <Col span={24}>
                    <Card title={<Typography.Title level={3}>ダッシュボード</Typography.Title>}>
                        <Form
                            layout='inline'
                            initialValues={{
                                date: defaultDates()
                            }}
                            size='large'
                            form={form}
                            onFinish={
                                ({ talent, date }) => {
                                    let talentFormValue;
                                    if (talent) {
                                        talentFormValue = talent;
                                        setSelectedTalent(JSON.parse(talent));
                                    } else {
                                        talentFormValue = defaultTalent();
                                        setSelectedTalent(JSON.parse(defaultTalent()));
                                        form.setFieldsValue({ talent: defaultTalent() });
                                    }
                                    setSelectedDate(date);
                                    localStorage.setItem('dashboard', JSON.stringify({ talent: talentFormValue, date }));
                                }
                            }
                        >
                        <Row gutter={[16,8]}>
                            <Col>
                                <Form.Item
                                    name='talent'
                                    label="タレント名"
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        style={{ width: 200 }}
                                        placeholder={(defaultTalent() &&
                                                      JSON.parse(defaultTalent()).name) || "選択してください"}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        { talents &&
                                            talents.map(
                                                talent => <Select.Option key={talent.name} value={JSON.stringify(talent)}>{talent.name}</Select.Option>
                                        )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name='date' label="日付" rules={[{ required: true, message: '日付を入力してください' }]} >
                                    <DatePicker.RangePicker
                                        disabledDate={ (current) => { return current && current > moment().endOf('day'); }}
                                        locale={locale}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button htmlType="submit" type='primary'>表示</Button>
                                </Form.Item>
                            </Col>
                            <Col>
                                { loading && <Form.Item><Spin/></Form.Item> }
                            </Col>
                        </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
            { Object.keys(data).length === 0 &&
            <div className='dashboard-box' style={{ padding: 100, height: 600, backgroundColor: 'white'}}>
                <Empty />
            </div>
            }
            <div className='dashboard-box' style={{ backgroundColor: 'white'}}>
                { data?.youtube_stats &&
                    <>
                        <Row>
                            <Space size={100} style={{ margin: '20px'}}>
                                <Typography.Title level={4}>YouTubeコメント</Typography.Title>
                            </Space>
                        </Row>
                        <Plot
                            style={{ width: '100%', height: '100%', minHeight: '600px' }}
                            data={data.youtube_stats.map(stats => (
                                {
                                    x: stats.x,
                                    y: stats.y,
                                    name: sentimentTranslation[stats.sentiment],
                                    type: 'bar'
                                }
                            ))}
                            config = {{
                                locales: { 'ja-JP': localeDictionary },
                                locale: 'ja-JP',
                                responsive: true
                            }}
                            layout={{
                                barmode: 'stack',
                                autosize: true,
                                hovermode: 'closest',
                                showlegend: true,
                            }}
                            onClick={
                                (node) => {
                                    const sentimentJapenese = node.points[0].data.name;
                                    const sentimentEnglish = Object.keys(sentimentTranslation).find(key => sentimentTranslation[key] === sentimentJapenese);
                                    const searchKeyword = `sentiment=${sentimentEnglish}`
                                    const dateStr = node.points[0].data.x[node.points[0].pointIndex];
                                    const clickedDate = dateStr.split(' - ');
                                    const startDate = moment(clickedDate, 'YYYY/MM/DD').startOf('day');
                                    const endDate = moment(clickedDate, 'YYYY/MM/DD').endOf('day');
                                    const dateRange = [startDate, endDate];

                                    node?.points && node.points.length > 0 && node.points[0].x && 
                                        history.push(`/keywordSearch/YoutubeComment/${selectedTalent.id}/${dateRange}/${searchKeyword}`)
                                }
                            }
                        />
                    </>
                }
            </div>
            <div className='dashboard-box' style={{ backgroundColor: 'white'}}>
                { data?.word_cloud?.y && data.word_cloud.x &&
                    <>
                        <Row>
                            <Space size={100} style={{ margin: '20px'}}>
                                <Typography.Title level={4}>ワードクラウド</Typography.Title>
                            </Space>
                        </Row>
                        <ReactWordcloud 
                            options={{
                                fontFamily: 'Noto Sans JP',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                padding: 1,
                                scale: 'sqrt',
                                spiral: 'archimedean',
                                colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'],
                                fontSizes: [20, 40],
                                enableTooltip: true,
                                deterministic: false,
                                svgAttributes: {
                                    width: '100%',
                                    height: '100%'
                                }
                            }}
                            words={
                                data.word_cloud.y.map((word, index) => {
                                    return {
                                        text: word,
                                        value: data.word_cloud.x[index]
                                    }
                                }) } 
                        />
                    </>
                }
            </div>
            <div className='dashboard-box' style={{ backgroundColor: 'white'}}>
                { data && data.yahoo_comments_count &&
                    <>
                        <Row>
                            <Space size={100} style={{ margin: '20px'}}>
                                <Typography.Title level={4}>YahooNews カテゴリー別カウント</Typography.Title>
                            </Space>
                        </Row>
                        <Plot
                            style={{ width: '100%', height: '100%', minHeight: '600px' }}
                            data={[
                                {
                                    hoverinfo: "text+x+y+z",
                                    marker: {
                                        colorbar: {
                                            len: 1,
                                            title: {
                                                text: "コメント数"
                                            },
                                            x: 1,
                                            xpad: 0,
                                            y: 0.5
                                        },
                                        colorscale: [
                                            [
                                                0,
                                                "rgb(12,51,131)"
                                            ],
                                            [
                                                0.25,
                                                "rgb(10,136,186)"
                                            ],
                                            [
                                                0.5,
                                                "rgb(242,211,56)"
                                            ],
                                            [
                                                0.75,
                                                "rgb(242,143,56)"
                                            ],
                                            [
                                                1,
                                                "rgb(217,30,30)"
                                            ]
                                        ],
                                        color: data.yahoo_comments_count.comment_count,
                                        showscale: true
                                    },
                                    name: "YahooNews カウント",
                                    showlegend: false,
                                    type: "bar",
                                    xaxis: "x",
                                    yaxis: "y",
                                    text: data.yahoo_comments_count.text,
                                    x: data.yahoo_comments_count.x,
                                    y: data.yahoo_comments_count.y
                                }
                            ]}
                            useResizeHandler
                            onClick={
                                (node) => {
                                    history.push(`/keywordSearch/YahooNewsArticle/${selectedTalent.id}/${selectedDate}/${node.points[0].text.split('<br>')[0]}`);
                                }
                            }
                            layout={{
                                autosize: true,
                                hovermode: 'closest',
                                plot_bgcolor: '#e5ecf6',
                                yaxis: { title: 'カウント数' }
                            }}
                            config = {{
                                locales: { 'ja-JP': localeDictionary },
                                locale: 'ja-JP'
                            }}
                        />
                    </>
                }
            </div>
            { data && data.beeswarm_chart && selectedCommentsCategory && data.beeswarm_chart[selectedCommentsCategory] &&
                <div className='dashboard-box' style={{ backgroundColor: 'white' }}>
                    <Row>
                        <Space size={100} style={{ margin: '20px'}}>
                            <Typography.Title level={4}>YahooNewsコメント</Typography.Title>
                            <CategorySelect
                                autosize={false}
                                defaultValue={selectedCommentsCategory}
                                handleChange={(value) => setSelectedCommentsCategory(value)}
                                categories={Object.keys(data.beeswarm_chart)}
                            />
                        </Space>
                    </Row>
                    <Row align={'middle'} style={{ marginLeft: '20px'}}>
                        <Col style={{ margin: '20px', backgroundColor: '#e5ecf6', textAlign: 'center', height: 550, width: 3000 }}>
                            <SwarmChart
                                selectedDate={selectedDate}
                                selectedNameId={selectedTalent.id}
                                data={data.beeswarm_chart[selectedCommentsCategory].filter(comment => comment.group)}
                            />
                        </Col>
                    </Row>
                </div>
            }
        </Space>
    </>;
}

export default Dashboard;
