import React from 'react';
import { useMutation } from '@apollo/client';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ADD_TALENTS_USER } from '../../mutations';
import propTypes from 'prop-types';

const AddTalentsButton = ({ selectedTalentIds, handleCompleted }) => {
    const [addTalents] = useMutation(ADD_TALENTS_USER, {
        onCompleted: (data) => handleCompleted(data.addTalentsUser.user),
        onError: (error) => {
            console.log(error);
        }
    });

    return <Button
        type='primary'
        disabled={selectedTalentIds.length === 0}
        onClick={() => addTalents({ variables: { input: { talentIds: selectedTalentIds } } })}
        icon={<PlusCircleOutlined/>}>
        担当リストに追加
    </Button>;

};

AddTalentsButton.propTypes = {
    handleCompleted: propTypes.func,
    selectedTalentIds: propTypes.array
};

export default AddTalentsButton;
