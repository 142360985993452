import React, { useState } from 'react';
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Input, Typography, message } from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_TALENT } from '../../mutations';

export const EditableTalentName = ({ name, id, refetch }) => {
    const [editing, setEditing] = useState(false);
    const [newName, setNewName] = useState(name);

    const [updateTalent] = useMutation(UPDATE_TALENT, {
        onError: (error) => {
            console.log(error);
        },
        onCompleted: () => {
            setEditing(false);
            message.success('タレント名を変更しました。', 4);
            refetch();
        }
    });

    const handleSubmit = () => {
        if (newName.length > 0) {
            const input = { id: id, name: newName };
            updateTalent({ variables: { input } });
        }
        else {
            message.error('タレント名を入力してください。', 4);
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {editing ? (
                <>
                    <Input
                        defaultValue={name}
                        onChange={(e) => setNewName(e.target.value)}
                        style={{ width: "80%" }}
                        onPressEnter={handleSubmit} />
                    <CheckCircleOutlined style={{ marginLeft: "1rem", fontSize: "1rem" }} onClick={() => handleSubmit()} />
                </>
            ) : (
                <>
                    <Typography.Text>{name}</Typography.Text>
                    <EditOutlined style={{ marginLeft: "1rem", fontSize: "1rem" }} onClick={() => setEditing(true)} />
                </>
            )}
        </div>
    );
};
