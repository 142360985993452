import React  from 'react';
import { Typography, Layout, Tabs, Card, Space } from 'antd';
import { TeamOutlined, SettingOutlined } from '@ant-design/icons';
import AccountInformation from './AccountInformation';
import UserManagement from './UserManagement';

const { Content } = Layout;

const UserSettings = () => {
    return <Layout>
        <Space direction='vertical' style={{ width: '100%', "padding-top": '15px'}}>
        <Card title={<Typography.Title level={3}>アカウント</Typography.Title>}>
        <Content className='site-layout-background'>
            <Tabs defaultActiveKey={'profile'}>
                <Tabs.TabPane tab='アカウント情報' key='accountInformation' icon={<SettingOutlined />}>
                    <AccountInformation/>
                </Tabs.TabPane>
                { JSON.parse(localStorage.currentUser).admin &&
                    <Tabs.TabPane tab='ユーザー管理' key='userManagement' icon={<TeamOutlined />}>
                    <UserManagement />
                </Tabs.TabPane> }
            </Tabs>
        </Content>
        </Card>
        </Space>
    </Layout>;
};

export default UserSettings;
