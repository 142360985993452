import React from 'react';
import {
    Select
} from 'antd';

const CategorySelect = ({ categories, handleChange, defaultValue }) => {
    return <>
        カテゴリー：
        <Select defaultValue={defaultValue} style={{ width: 200 }} placeholder="選択してでください" onChange={handleChange}>
            { categories.map(category => (
                <Select.Option key={category} value={category}>
                  { category }
                </Select.Option>
            )) }
       </Select>
    </>
};

export default CategorySelect;
