import React, { useState, useEffect } from 'react';
import { useStickyState } from '../../Utilities/useStickyState';
import { GRAVITY_API_ROOT } from '../../constants';
import { Space, Spin, Input, Table, Typography, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import * as moment from 'moment';
import { authenticationService } from '../../Utilities/authenticationService';

const TalentCloud = () => {
    const [stats, setStats] = useState([]);
    const [searchTerm, setSearchTerm] = useStickyState('', 'gravityTalentCloudSearchTerm');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleSearch = (value) => {
        setSearchTerm(value);
    }

    useEffect(() => {
        if (searchTerm.length > 0) {
            setLoading(true);
            fetch(`${GRAVITY_API_ROOT}/talent_cloud?search_term=${searchTerm}`, {
                headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${authenticationService.currentUserValue.token}`
            }
            }).then(response => response.json()).then(reader => {
                setLoading(false);
                setStats(reader.map((item, index) => {return {...item, key: index}}));
            })
        }
    }, [searchTerm, setStats]);

    const columns = [
        {
            title: <Typography.Text strong>タレント名</Typography.Text>,
            dataIndex: 'talent_name',
            width: '25%',
        },
        {
            title: <Typography.Text strong>検索単語を含むツイート数</Typography.Text>,
            dataIndex: 'contained',
            sorter: (a, b) => a.contained - b.contained,
            render: (text, record) => <Button 
                                type='link'
                                onClick={
                                    (node) => {
                                        const pastMonth = [moment().subtract(1, 'months'), moment()];
                                        history.push(`/keywordSearch/TwitterSearchResult/${record.talent_id}/${pastMonth}/${searchTerm}`);
                                    }
                                } >{text}</Button>,
            align: 'right',
            width: '25%',
        },
        {
            title: <Typography.Text strong>総ツイート数</Typography.Text>,
            dataIndex: 'n',
            sorter: (a, b) => a.n - b.n,
            align: 'right',
            width: '25%',
        },
        {
            title: <Typography.Text strong>割合</Typography.Text>,
            dataIndex: 'ratio',
            sorter: (a, b) => a.ratio - b.ratio,
            render: (text, record) => <Typography.Text>{Math.round(text*10000) / 100}%</Typography.Text>,
            align: 'right',
            width: '25%',
        }
    ];

    return (
        <Space direction='vertical' style={{ width: '80%' }} size='large'>
            <Input.Search 
                defaultValue={searchTerm} 
                onSearch={handleSearch} 
                enterButton="検索" 
                placeholder="キーワード" 
                style={{ width: '50%' }} 
                allowClear />
            { loading ? 
                <Spin /> 
                : 
                <Table columns={columns} dataSource={stats} /> }
        </Space>
    )
}

export default TalentCloud;