import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, Row, Space } from 'antd';
import { useMutation } from '@apollo/client';
import { CREATE_TALENT } from '../../mutations';
import propTypes from 'prop-types';

const AddTalentModal = ({ handleCompleted, visible, hideModal }) => {
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.
    const [createTalent] = useMutation(CREATE_TALENT, {
        onCompleted: (data) => {
            handleCompleted(data.createTalent.talent);
            hideModal();
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const onFinish = ({ name, twitterAccount }) => {
        createTalent({ variables: { input: { name, twitterAccount } } });
    };

    useEffect(() => {
        forceUpdate({});
    }, []);

    return <Modal
            title="タレントの新規追加"
            visible={visible}
            onOk={hideModal}
            onCancel={hideModal}
            footer={null}
		>
		<Form
			name='newtalent'
			form={form}
			layout='horizontal'
            onFinish={onFinish}
			labelCol={{ span: 8 }}
			labelAlign='left'
		>
			<Form.Item
				label="タレント名"
				name='name'
				rules={[{ required: true, message: '名が必要です' }]}
				style={{ marginBottom: '16px' }}
			>
				<Input placeholder='選択してください' />
			</Form.Item>
            <Form.Item
                label="Twitterアカウント"
				name='twitterAccount'
			>
				<Input placeholder='入力してください　例：@gravity' />
			</Form.Item>
			<Row justify='end'>
				<Space>
					<Button onClick={hideModal}>キャンセル</Button>
					<Form.Item shouldUpdate noStyle>
						{ () => (
							<Button
								disabled={ !form.isFieldTouched('name') ||
										form.getFieldsError().filter(({ errors }) => errors.length).length}
								type="primary"
								htmlType='submit'
							>
								追加する
							</Button>
						)}
					</Form.Item>
				</Space>
			</Row>
		</Form>
    </Modal>;
};

AddTalentModal.propTypes = {
    handleCompleted: propTypes.func
};

export default AddTalentModal;
