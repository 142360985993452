import React, { useState, useEffect } from 'react';
import { Space, Typography, Card, Button, Spin, message } from 'antd';
import { PlusOutlined, UploadOutlined, SyncOutlined, DownloadOutlined } from '@ant-design/icons';
import { GET_PRESIGNED_UPLOAD_URL, GET_POWERPOINT_FILES } from '../../queries';
import { uploadToS3, useS3Upload } from '../../Utilities/useS3Upload';
import { useLazyQuery } from '@apollo/client';
import './fileArea.less';

const PRIMARY_GREY = '#262626'
const SECONDARY_GREY = '#8C8C8C'

const FileArea = () => {
    const [file, setFile] = useState();
    const [currentStatus, setCurrentStatus] = useState('beforeUpload');
    const [powerpointFiles, setPowerpointFiles] = useState([]);

    const [getPowerpointFiles, { data: powerpointData, refetch }] = useLazyQuery(GET_POWERPOINT_FILES);

    const [getPresignedUploadUrl, { loading, data }] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL, {
        onCompleted: (data) => {
            uploadToS3(
                file,
                data.presignedUploadUrl.uploadUrl,
                (response) => {
                    console.log('ERROR!' + response.code);
                    message.error('ファイルのアップロードに失敗しました。');
                },
                () => {
                    message.success('ファイルをアップロードしました。');
                    setCurrentStatus('uploadComplete');
                }
            );
        }
    });

    const { getRootProps, getInputProps } = useS3Upload({
        presignedUploadUrl: data && data.presignedUploadUrl.uploadUrl,
        onUploadStart: acceptedFiles => {
            setFile(acceptedFiles[0]);
            setCurrentStatus('loadingUpload');
            getPresignedUploadUrl({ variables: { objectKey: acceptedFiles[0].name } });

        }
    });

    useEffect(() => {
        if (currentStatus === 'beforeUpload') {
            getPowerpointFiles();
        } else if (currentStatus === 'uploadComplete') {
            refetch();
        }
    }, [getPowerpointFiles, currentStatus, refetch]);

    useEffect(() => {
        if (powerpointData?.powerpointFiles) {
            setPowerpointFiles(powerpointData.powerpointFiles);
        }
    }, [powerpointData]);

    if (loading) { return <SyncOutlined spin />; }

    return (
        <Space direction='vertical' style={{ width: '100%' }}>
            <Card title={<Typography.Title level={3}>分析テンプレート</Typography.Title>}>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    <Card
                        style={{ width: 400, height: 310, backgroundColor: '#F5F5F5', color: SECONDARY_GREY }}
                        id='upload-zone' {...getRootProps()}
                        className='file-card'
                    >
                        <input {...getInputProps()} />
                        <div style={{ height: 250, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            {currentStatus === 'loadingUpload' ?
                            <Spin /> :
                            <>
                                <PlusOutlined className='card-item' style={{ fontSize: 30 }}/>
                                <Typography.Text className='card-item' style={{ color: 'inherit' }}>ここにドラッグ＆ドロップ</Typography.Text>
                                <Typography.Text className='card-item' style={{ fontSize: 12, color: 'inherit' }}>または</Typography.Text>
                                <Button className='card-item' style={{ color: PRIMARY_GREY }} icon={<UploadOutlined/>}>アップロード</Button>
                            </>
                            }
                        </div>
                    </Card>
                    {powerpointFiles.length > 1 && powerpointFiles.map(file => (
                        <Card
                            actions={[
                                <Button type='text' href={file.download_url} rel='no' target='_blank' icon={<DownloadOutlined/>} />
                            ]}
                            style={{ width: 400, height: 310 }}
                            className='file-card'
                        >
                            <div style={{ height: 200, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography.Text>{file.filename.split('.ppt')[0]}</Typography.Text>
                            </div>
                        </Card>
                    ))}
                </div>
            </Card>
        </Space>
    )
};

export default FileArea;