import React from 'react';
import { ResponsiveSwarmPlot } from '@nivo/swarmplot';
import { useHistory } from 'react-router-dom';

const SwarmChart = ({ data, colorScheme, selectedNameId, selectedDate }) => {
    const votes = data.map(comment => comment.votes);
    const history = useHistory();

    return <ResponsiveSwarmPlot
        data={data}
        size={{ key: 'votes', values: [Math.min(...votes), Math.max(...votes)], sizes: [ 6, 20 ] }}
        forceStrength={4}
        simulationIterations={100}
        padding={6}
		animate={false}
        enableLabel={false}
        onClick={
            (node) => {
                history.push(`/keywordSearch/YahooNewsComment/${selectedNameId}/${selectedDate}/${node.label}`);
            }
        }
        spacing={6}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
        margin={{ top: 80, right: 80, bottom: 80, left: 80 }}
        groups={[ 'ポジティブコメントに賛成', 'ポジティブコメントに反対', 'ネガティブコメントに賛成', 'ネガティブコメントに反対' ]}
        value='votes'
        motionStiffness={50}
        motionDamping={10}
    />
};

export default SwarmChart;
