import React, { useState, useMemo } from 'react';
import { GRAVITY_API_ROOT } from '../../constants';
import { authenticationService } from '../../Utilities/authenticationService';
import {
    Input,
    Button,
    Typography,
    Divider,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
const { Text } = Typography;

const SummarizeComments = ({comments, talentName}) => {
    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [conversationHistory, setConversationHistory] = useState([]);

    const organizeCommentsByTitle = (comments) => {
        const organizedComments = {};
        comments.forEach((comment) => {
            const title = comment.videoTitle ?? comment.title ?? "No title";
            const content = comment.comment ?? comment.content ?? comment.description ?? "No comment";
            
            // For youtube and yahoo comments 
            if (comment.hasOwnProperty('videoTitle') | comment.hasOwnProperty('sentimentType')) {
                // For yahoo comments and youtube comments use
                const goodCount = comment.scoreGood ?? comment.likeCount ?? 0
                const badCount = comment.scoreBad ?? 0
                if (!organizedComments[title]) {
                    organizedComments[title] = {
                        comments: [],
                        good: [],
                        bad: []
                    };
                } else {
                    organizedComments[title].comments.push(content);
                    organizedComments[title].good.push(goodCount);
                    organizedComments[title].bad.push(badCount);
                } 
            } else {
                // For yahoo news and youtube videos
                const commentCount = comment.commentCount ?? comment.comment
                const viewCount = comment.viewCount ?? 0

                if (!organizedComments['data']) {
                    organizedComments['data'] = {
                        titles: [],
                        contents: [],
                        commentCounts: [],
                        viewCounts: [],
                    };
                } 
                organizedComments.data.titles.push(title);
                organizedComments.data.contents.push(content);
                organizedComments.data.commentCounts.push(commentCount)
                organizedComments.data.viewCounts.push(viewCount)
            }



        });
        console.log(organizedComments)
        return organizedComments;
    };

    const organizedComments = useMemo(() => {
        return JSON.stringify(organizeCommentsByTitle(comments))
    }, [comments])
    
    const text_sys = `You are helpful assistant to answer a question from the provided data. 
    The data is ${organizedComments.slice(0,50000)} and it holds title, content, count, good and bad scores if any. 
    (1) Please give your answer in Japanese. 
    (2) Some typical questions are related to ratio of comments, total number of comments on specific titles, summarization of comments. 
    (3) Make sure to use the count column for youtube comments questions
    (4) For math question, please answer with equations`

    const handleSearch = () => {
        setLoading(true);

        const messages = conversationHistory.length === 0
        ? [
            ...conversationHistory,
            { role: 'system', content: text_sys },
            { role: 'user', content: question }
          ] : [
            ...conversationHistory,
            { role: 'user', content: question }
          ];

        fetch(`${GRAVITY_API_ROOT}/openai/chat_completions`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${authenticationService.currentUserValue.token}`,
                'Content-Type' : 'application/json',
            },
            body : JSON.stringify({
                messages: messages
            }),
        })
            .then(response => response.json())
            .then(data => {
                const answer = data?.response?.choices?.[0]?.message?.content ?? 'No answer available';
                setLoading(false)
                setConversationHistory([...messages, { role: 'system', content: answer }])
                setQuestion(''); // Clear the text field after handling search
            })
            .catch(error => {
                console.error('Error:', error)
                setLoading(false)
            })
    }

    const handleChange = (e) => {
        setQuestion(e.target.value);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '16px' }}>
            <div style={{ display: 'flex', marginBottom: '16px' }}>
                <Input
                    placeholder="質問を入力してください (例: 〇〇の演技についての評価を教えてください)"
                    style={{ width: '80%', marginRight: '1%'}}
                    value={ question }
                    onChange={ handleChange }
                />
                <Button type="primary" onClick={handleSearch} style={{ width: '10%', marginRight: '1%' }} loading={loading} disabled={question === ''}>
                    質問する
                </Button>
                <Button type="primary" onClick={() => setConversationHistory([])} style={{ width: '8%'}} icon={<DeleteOutlined/>} 
                        disabled={conversationHistory.length === 0}>
                        消去
                </Button>
            </div>
            {conversationHistory.map((message, index) => {
                if (message.role === 'system' && message.content.includes("You are helpful assistant to answer a question from the provided data."))     {
                    return null; // Exclude system message with same content as text_sys
                }
                return (
                    <React.Fragment key={index}>
                        <Text style={{ marginBottom: '8px' }}>{message.content}</Text>
                        {message.role === 'system' && <Divider />} {/* Render Divider after every system message */}
                    </React.Fragment>
                );
            })}
        </div>
      );
} 
 
export default SummarizeComments