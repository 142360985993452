import React from 'react';
import { useMutation } from '@apollo/client';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { DELETE_TALENTS_USER } from '../../mutations';
import propTypes from 'prop-types';

const DeleteTalentsButton = ({ selectedTalentIds, handleCompleted }) => {
    const [deleteTalents] = useMutation(DELETE_TALENTS_USER, {
        onCompleted: (data) => handleCompleted(data.deleteTalentsUser.user),
        onError: (error) => {
            console.log(error);
        }
    });

    return <Button
        onClick={() => deleteTalents({ variables: { input: { talentIds: selectedTalentIds } } })}
        disabled={selectedTalentIds.length === 0}
        icon={<DeleteOutlined/>}>
        削除
    </Button>;
};

DeleteTalentsButton.propTypes = {
    handleCompleted: propTypes.func,
    selectedTalentIds: propTypes.array
};

export default DeleteTalentsButton;
