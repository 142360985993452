import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Tag, Select } from 'antd';

export const EditableTalentManagers = ({ talentsUsers = [], users = { edges: [] }, id, addTalentsUser, deleteTalentsUser }) => {
    const [editing, setEditing] = useState(false);

    const handleCreate = (e) => {
        addTalentsUser({ variables: { input: { talentId: id, userId: e } } });
        setEditing(false);
    };

    const handleDelete = (userId) => {
        deleteTalentsUser({ variables: { input: { talentId: id, userId } } });
        setEditing(false);
    };

    const remainingManagers = users.edges.filter(
        (u) => !talentsUsers.find((tu) => tu.id === u.node.id)
    );

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {talentsUsers.map((talentUser) => {
                return (
                    <Tag closable key={talentUser.id} onClose={() => handleDelete(talentUser.id)}>
                        {talentUser.lastName + " " + talentUser.firstName}
                    </Tag>
                );
            })}
            {editing ? (

                <Select defaultOpen={true} autoFocus={true} style={{ width: "10rem" }} onChange={handleCreate}>
                    {remainingManagers.map((u) => (
                        <Select.Option key={u.node.id}>
                            {u.node?.lastName} {u.node?.firstName}
                        </Select.Option>
                    ))}
                </Select>
            ) : (
                remainingManagers.length > 0 && <Button size="small" icon={<PlusOutlined />} onClick={() => setEditing(true)} />
            )}
        </div>
    );
};
