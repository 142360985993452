import React, { useState, useEffect } from 'react';
import { Alert, Typography, Form, Input, Button, Row, Space } from 'antd';
import { CHANGE_PASSWORD } from '../../mutations';
import { useMutation } from '@apollo/client';
import { authenticationService } from '../../Utilities/authenticationService';
import propTypes from 'prop-types';

const PasswordChangeForm = ({ handleCompleted, returnToAccountInfo }) => {
    const [error, setError] = useState(null);

    const [changePassword] = useMutation(CHANGE_PASSWORD, {
        onCompleted: (data) => {
            handleCompleted(data.changePasswordUser.user);
        },
        onError: (error) => {
            setError(error.networkError.result.errors.map(err => err.message));
        }
    });

    const onFinish = ({ oldPassword, password, passwordConfirmation }) => {
        changePassword({
            variables: {
                input: {
                    id: authenticationService.currentUserValue.id,
                    oldPassword,
                    password,
                    passwordConfirmation
                }
            }
        });
    };

    const [form] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate({});
    }, []);

    const validateMessages = {
        // Disabling because this template form comes from Ant and is identified as a rule violation
        // eslint-disable-next-line
        required: '${label}を入力してください。'
    };

    return <>
        <Form form={form}
            labelCol={ { span: 4 } }
            wrapperCol={ { span: 8 } }
            layout='vertical'
            validateMessages={validateMessages}
            name="updatePasswordForm"
            onFinish={onFinish}
        >
            <Form.Item>
                <Typography.Title level={5}>パスワード変更</Typography.Title>
            </Form.Item>
            { error && <Form.Item>
                <Alert message={error} type='error' showIcon closable />
            </Form.Item> }
            <Form.Item
                name="oldPassword"
                label="現在のパスワード"
                rules={[{ required: true }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                name="password"
                label="新しいパスワード"
                hasFeedback
                rules={[
                    { 
                        required: true 
                    },({ getFieldValue }) => ({
                        validator(rule, value) {
                            // Password must be 8, 9, 10, 11 or 12 characters long.
                            if(value.length < 8 || value.length > 12) {
                                return Promise.reject('半角英数字で8文字以上12文字以内で入力し、半角スペースなどが含まれていないかご確認ください。')
                            }

                            // Match only letters or numbers.
                            if(!value.match(/^[0-9a-zA-Z]+$/)) {
                                return Promise.reject("半角英数字以外は使用できません。")
                            }
               

                            return Promise.resolve();
                        }
                    })
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                shouldUpdate
                name="passwordConfirmation"
                label="新しいパスワード(確認)"
                hasFeedback
                rules={[
                    {
                        required: true
                    }, ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject('入力した2つのパスワードが一致しません。');
                        }
                    })
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Row>
        <Space>
            <Form.Item shouldUpdate>
                { () => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                            !form.isFieldTouched('oldPassword') || !form.isFieldTouched('password') ||
                            !form.isFieldTouched('passwordConfirmation') ||
                            form.getFieldsError().filter(({ errors }) => errors.length).length}
                    >
                        変更を保存する
                    </Button>
                )}
            </Form.Item>
               <Form.Item>
                    <Button onClick={returnToAccountInfo} >
                        キャンセル
                    </Button>
                </Form.Item>
                </Space>
                </Row>
        </Form>
    </>;
};

PasswordChangeForm.propTypes = {
    handleCompleted: propTypes.func
};

export default PasswordChangeForm;
