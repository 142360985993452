import React from 'react';
import './App.less';
import { authenticationService } from './Utilities/authenticationService';
import {
    LoginForm,
    UserSettings,
    TalentsTable,
    TalentCloud,
    PrivateRoute,
    KeywordSearch,
    Dashboard,
    FileArea
} from './Components';
import { PATHS, GRAVITY_API_ROOT }  from './constants';
import {
    BrowserRouter as Router,
    //HashRouter as Router, // Needed to Github pages to understand SPA
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import history from './Utilities/history';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost';
import { setContext } from '@apollo/client/link/context';

class App extends React.Component {
    state = {
        currentUser: null
    };

    componentDidMount = () => {
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }

    handleLogout = () => {
        authenticationService.logout();
        history.push('/login');
    }

    render = () => {
        const { currentUser } = this.state;
        const httpLink = new HttpLink({ uri: GRAVITY_API_ROOT + '/graphql' });
        const authLink = setContext((_, { headers }) => {
            const token = localStorage.getItem('currentUser');
            return {
                headers: {
                    ...headers,
                    Authorization: token ? `Bearer ${currentUser.token}` : ''
                }
            };
        });

        const client = new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache()
        });

        return (
            <ApolloProvider client={client}>
                <Router basename={ process.env.PUBLIC_URL }>
                    <Switch>
                        <PrivateRoute disableBorder path={ PATHS.dashboard } component={Dashboard} onLogout={this.handleLogout} />
                        <PrivateRoute disableBorder path={ PATHS.talentCloud } component={TalentCloud} onLogout={this.handleLogout} />
                        <PrivateRoute disableBorder path={ PATHS.talents } component={TalentsTable} onLogout={this.handleLogout}/>
                        <PrivateRoute disableBorder path={ PATHS.keywordSearch } component={KeywordSearch} onLogout={this.handleLogout}/>
                        <PrivateRoute disableBorder path={ PATHS.userSettings } component={UserSettings} onLogout={this.handleLogout}/>
                        <PrivateRoute disableBorder path={ PATHS.fileArea } component={FileArea} onLogout={this.handleLogout}/>
                        <Route path={ PATHS.login } component={LoginForm}/>
                        <Route render={() => <Redirect to={PATHS.talents} />} />
                    </Switch>
                </Router>
            </ApolloProvider>
        );
    }
}

export default App;
