import React, { useState } from 'react';
import { Button, Modal, Descriptions, Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { PASSWORD_RESET } from '../../mutations';
import propTypes from 'prop-types';

const PasswordResetButton = ({ userId }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [passwordReset] = useMutation(PASSWORD_RESET, {
        onCompleted: (data) => {
            setUserInfo(data.passwordResetUser.user);
            setModalVisible(true);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    return <>
        <Popconfirm
            title="パスワードをリセットしますか。"
            icon={<QuestionCircleOutlined />}
            okText='リセットする'
            cancelText='キャンセル'
            onConfirm={() => passwordReset({ variables: { input: { id: userId } } })}
        >
            <Button data-testid='reset-button' type='link'>
                パスワードをリセット
            </Button>
        </Popconfirm>
        <Modal
            title="パスワードをリセット"
            visible={modalVisible}
            onOk={() => setModalVisible(false)}
            onCancel={() => setModalVisible(false)}
            footer={null}
        >
            <Descriptions layout='vertical'>
                <Descriptions.Item label='メール' span={3}>{userInfo.email}</Descriptions.Item>
                <Descriptions.Item label='新しいパスワード' span={3}>{userInfo.password}</Descriptions.Item>
            </Descriptions>
        </Modal>
    </>;
};

PasswordResetButton.propTypes = {
    userId: propTypes.string
};

export default PasswordResetButton;
