import React, { useState, useEffect } from 'react';
import { Alert, Button, Typography, Row, Col, Space, Input, Table, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { GET_USERS } from '../../queries';
import { authenticationService } from '../../Utilities/authenticationService';
import history from '../../Utilities/history';
import AddUserModal from './AddUserModal';
import NewUserModal from './NewUserModal';
import DeleteUsersButton from './DeleteUsersButton';
import PasswordResetButton from './PasswordResetButton';

const columns = [
    {
        title: <Typography.Text strong>氏名</Typography.Text>,
        dataIndex: 'fullName'
    },
    {
        title: <Typography.Text strong>メール</Typography.Text>,
        dataIndex: 'email'
    },
    {
        dataIndex: 'passwordReset',
        className: 'align-right'
    }
];

const UserManagement = () => {
    const [currentModal, setCurrentModal] = useState('table');
    const [newUserCreated, setNewUserCreated] = useState(false);
    const [usersDeleted, setUsersDeleted] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const { data, error, loading, refetch } = useQuery(GET_USERS);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (data) {
            setRows(data.users.edges.map(
                user => ({
                    key: user.node.id,
                    fullName: `${user.node.lastName} ${user.node.firstName}`,
                    email: user.node.email,
                    userId: user.node.id,
                    passwordReset: <PasswordResetButton userId={user.node.id} />
                })
            ));
        }
    }, [data]);

    if (loading) { return <Skeleton />; }

    if (error) {
        console.log(error)
        authenticationService.logout();
        history.push('/login');
        return <Skeleton />
    }

    const resetSearch = (data) => {
        if (data) {
            setRows(data.users.edges.map(
                user => ({
                    key: user.node.id,
                    fullName: `${user.node.lastName} ${user.node.firstName}`,
                    email: user.node.email,
                    userId: user.node.id,
                    passwordReset: <PasswordResetButton userId={user.node.id} />
                })
            ));
        }
    };

    const handleSearch = searchField => {
        if (searchField.target.value === '') {
            resetSearch(data);
        } else {
            setRows(
                data.users.edges.map(
                    user => {
                        if (user.node.lastName.includes(searchField.target.value) ||
                            user.node.firstName.includes(searchField.target.value)) {
                            return {
                                key: user.node.id,
                                fullName: `${user.node.lastName} ${user.node.firstName}`,
                                email: user.node.email,
                                userId: user.node.id,
                                passwordReset: <PasswordResetButton userId={user.node.id} />
                            }
                        } else {
                            return false;
                        }
                    }
                ).filter(Boolean)
            )
        }
    };

    const returnToTable = () => {
        setCurrentModal('table');
    };

    const TableHeader = <React.Fragment>
        <Row>
            <Col span={4} align='left'>
                <Input.Search onChange={handleSearch} allowClear placeholder="検索" />
            </Col>
            <Col style={{flex: 1}} align='right'>
                <Space>
                    <Button onClick={ () => setCurrentModal('addUser') } icon={<PlusOutlined/>}>新規追加</Button>
                    <DeleteUsersButton
                        selectedUserIds={selectedUserIds}
                        handleCompleted={ (usersInfo) => { setCurrentModal('table'); setUsersDeleted(usersInfo); refetch(); }} />
                </Space>
            </Col>
        </Row>
        <Row>
            { newUserCreated &&
                <>
                    <Col span={24}>
                        <Alert message="新規ユーザーを追加しました。" type="success" showIcon closable/>
                    </Col>
                    <NewUserModal userInfo={newUserCreated} />
                </>
            }
            { usersDeleted &&
                <>
                    <Col span={24}>
                        <Alert
                            message={`ユーザーID "${usersDeleted.map(user => user.email)}"を消しました`}
                            type="success"
                            showIcon
                            closable
                        />
                    </Col>
                </>
            }
        </Row>
    </React.Fragment>;

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log('selectedRows: ', selectedRows);
            setSelectedUserIds(selectedRows.map(row => row.userId));
        }
    };

    return <React.Fragment>
        { currentModal === 'table' &&
            <Table
                columns={columns}
                dataSource={rows}
                rowSelection={{ type: 'checkbox', ...rowSelection }}
                title={() => TableHeader}
            />
        }
        { currentModal === 'addUser' &&
            <AddUserModal
                handleCompleted={ (userInfo) => { setCurrentModal('table'); setNewUserCreated(userInfo); refetch(); } }
                returnToTable={returnToTable}
            />
        }
    </React.Fragment>;
};

export default UserManagement;
