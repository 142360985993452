import React, { useCallback, useState, useEffect } from 'react';
import { ExportOutlined, UsergroupAddOutlined, CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button, Skeleton, Alert, Tabs, Col, Row, Input, Table, Typography, Tag, Space, Card, message  } from 'antd';
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom';
import AddTalentsToUserButton from './AddTalentsToUserButton';
import DeleteTalentsButton from './DeleteTalentsButton';
import AddTalentModal from './AddTalentModal';
import { useQuery, useMutation } from '@apollo/client';
import { GET_TALENTS, GET_USERS } from '../../queries';
import { UPDATE_TALENT, DELETE_TALENTS_USER, ADD_TALENTS_USER } from '../../mutations';
import { authenticationService } from '../../Utilities/authenticationService';
import { CSVLink } from "react-csv";
import { EditableTalentName } from './EditableTalentName';
import { EditableTalentManagers } from './EditableTalentManagers';

const TalentsTable = () => {
    const { path } = useRouteMatch();
    const [rows, setRows] = useState([]);
    const [ownTalents, setOwnTalents] = useState([]);
    const [newTalentCreated, setNewTalentCreated] = useState(false);
    const [talentDeleted, setTalentDeleted] = useState(false);
    const [activeTab, setActiveTab] = useState('list');
    const [selectedTalentIds, setSelectedTalentIds] = useState([]);
    const [showAddTalentModal, setShowAddTalentModal] = useState(false);
    const { data, loading, refetch } = useQuery(GET_TALENTS, {
        fetchPolicy: 'network-only',
    });

    const currentUserId = authenticationService.currentUserValue.id;

    const [updateTalent] = useMutation(UPDATE_TALENT, {
        onError: (error) => {
            console.log(error);
        }
    });

    const { data: usersData } = useQuery(GET_USERS, {
        onError: (error) => {
            console.log(error);
        }
    });

    const [addTalentsUser] = useMutation(ADD_TALENTS_USER, {
        onError: (error) => {
            console.log(error);
        },
        onCompleted: () => {
            refetch()
        }
    });

    const [deleteTalentsUser] = useMutation(DELETE_TALENTS_USER, {
        onError: (error) => {
            console.log(error);
        },
        onCompleted: () => {
            refetch()
        }
    });

    const columns = [
        {
            title: <Typography.Text strong>タレント名</Typography.Text>,
            dataIndex: 'name',
            render: (text, record) => EditableTalentName({ name: text, id: record.id, refetch })
        },
        {
            title: <Typography.Text strong>マネージャー</Typography.Text>,
            dataIndex: 'manager',
            render: (text, record) => EditableTalentManagers({ talentsUsers: record.talentsUsers, users: usersData?.users, id: record.id, refetch, addTalentsUser, deleteTalentsUser })
        },
        {
            title: <Typography.Text strong>スクレーパー稼働状況</Typography.Text>,
            dataIndex: 'isScraped'
        },
        {
            dataIndex: 'action',
            className: 'align-right'
        }
    ];

    const handleToggleScrapeButton = useCallback(
        (talent) => {
            const input = { id: talent.node.id, isScraped: !talent.node.isScraped };
            updateTalent({ variables: { input } });
        },
        [updateTalent]
    );

    const actionLinks = (talent) => (
        <Space direction='vertical'>
            <Link to={{ pathname: `/dashboard/${talent.node.id}` }}>データを確認</Link>
            <Link to={{ pathname: `/keywordSearch/YahooNewsArticle/${talent.node.id}` }}>ラベルツールを確認</Link>
        </Space>
    );

    const scrapeToggle = useCallback(
        (talent) => {
            const tagText = talent.node.isScraped ? '稼働中' : '停止中';
            const tagColor = talent.node.isScraped ? 'success' : 'default';
            const tagIcon = talent.node.isScraped ? <CheckCircleOutlined /> : <MinusCircleOutlined />;
            const buttonText = talent.node.isScraped ? 'Stop' : 'Start';
            const buttonType = talent.node.isScraped ? 'default' : 'primary';
            const buttonDanger = talent.node.isScraped;
            return (
                <Space direction='horizontal'>
                    <Tag icon={ tagIcon } color={tagColor} >{tagText}</Tag>
                    <Button 
                        size="small" 
                        onClick={ () => handleToggleScrapeButton(talent) }
                        danger={buttonDanger}
                        type={buttonType}>
                        { buttonText }
                    </Button>
                </Space>
            )
        },
        [handleToggleScrapeButton]
    );

    const resetSearch = useCallback(
        (data) => {
            if (data) {
                setRows(data.talents.edges.map(talent => ({
                    key: talent.node.name,
                    name: talent.node.name,
                    id: talent.node.id,
                    isScraped: scrapeToggle(talent),
                    manager: talent.node.users.map(user => `${user.lastName} ${user.firstName}`).join(', '),
                    talentsUsers: talent.node.users,
                    action: actionLinks(talent)
                })));
            }
        },
        [scrapeToggle]
    );

    useEffect(() => {
        resetSearch(data);
        if (data) {
            const ownedTalents = data.talents.edges.map(
                talent => {
                    if (talent.node.users.find(user => parseInt(user.id) === currentUserId)) {
                        return talent;
                    }
                    return false;
                }
            ).filter(Boolean);
            setOwnTalents(ownedTalents.map(talent => ({
                key: talent.node.name,
                name: talent.node.name,
                id: talent.node.id,
                manager: talent.node.users.map(user => `${user.lastName} ${user.firstName}`).join(', '),
                talentsUsers: talent.node.users,
                isScraped: scrapeToggle(talent),
                action: actionLinks(talent)
            })));
        }
    }, [resetSearch, data, currentUserId, scrapeToggle]);

    if (loading) {
        return <Skeleton />;
    }

    const handleSearch = searchField => {
        if (searchField.target.value === '') {
            resetSearch(data);
            const ownedTalents = data.talents.edges.map(
                talent => {
                    if (talent.node.users.find(user => parseInt(user.id) === currentUserId)) {
                        return talent;
                    }
                    return false;
                }
            ).filter(Boolean);
            setOwnTalents(ownedTalents.map(talent => ({
                key: talent.node.name,
                name: talent.node.name,
                id: talent.node.id,
                manager: talent.node.users.map(user => `${user.lastName} ${user.firstName}`).join(', '),
                isScraped: scrapeToggle(talent),
                action: actionLinks(talent)
            })));
        } else {
            setRows(
                data.talents.edges.map(talent => {
                    if (talent.node.name.includes(searchField.target.value)) {
                        return {
                            key: talent.node.name,
                            name: talent.node.name,
                            id: talent.node.id,
                            manager: talent.node.users.map(user => `${user.lastName} ${user.firstName}`).join(', '),
                            isScraped: scrapeToggle(talent),
                            action: actionLinks(talent)
                        }
                    } else {
                        return false;
                    }
                }).filter(Boolean)
            );
            setOwnTalents(
                data.talents.edges.map(talent => {
                    if (talent.node.users.find(user => parseInt(user.id) === currentUserId) &&
                        talent.node.name.includes(searchField.target.value)) {
                        return {
                            key: talent.node.name,
                            name: talent.node.name,
                            id: talent.node.id,
                            manager: talent.node.users.map(user => `${user.lastName} ${user.firstName}`).join(', '),
                            isScraped: scrapeToggle(talent),
                            action: actionLinks(talent)
                        }
                    } else {
                        return false;
                    }
                }).filter(Boolean)
            );
        }
    };

    const showModal = () => {
        setShowAddTalentModal(true);
    };

    const hideModal = () => {
        setShowAddTalentModal(false);
    };

    const CSVdata = activeTab === 'all' ?
        [['ID', 'タレント名', 'マネージャー']].concat(rows.map(row => [row.id, row.name, row.manager])) :
        [['ID', 'タレント名', 'マネージャー']].concat(ownTalents.map(row => [row.id, row.name, row.manager]));

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log('selectedRows: ', selectedRows);
            setSelectedTalentIds(selectedRows.map(row => row.id));
        }
    };

    const TableHeader = <>
        {
            newTalentCreated &&
                <>
                    <Row>
                        <Col span={24}>
                            <Alert message="担当リストに追加しました。" type="success" showIcon closable/>
                        </Col>
                    </Row>
                    <br/>
                </>
        }
        {
            talentDeleted &&
                <>
                    <Row>
                        <Col span={24}>
                            <Alert message="担当リストから削除しました。" type="success" showIcon closable/>
                        </Col>
                    </Row>
                    <br/>
                </>
        }
        <Row>
            <Col span={4}>
                <Input.Search onChange={handleSearch} placeholder="検索" allowClear />
            </Col>
            <Col span={20}>
                <Row gutter={[16, 0]} type="flex" justify="end">
                    <Col>
                        { activeTab === 'all' &&
                            <Button 
                                icon={<UsergroupAddOutlined />} 
                                type='primary'
                                onClick={showModal}
                            >
                                タレントの新規追加
                            </Button>
                        }
                    </Col>
                    <Col>
                        { activeTab === 'all' &&
                            <AddTalentsToUserButton
                                selectedTalentIds={selectedTalentIds}
                                handleCompleted={() => { refetch(); setNewTalentCreated(true); } }
                            />
                        }
                    </Col>
                    <Col>
                        { activeTab === 'list' &&
                            <DeleteTalentsButton
                                selectedTalentIds={selectedTalentIds}
                                handleCompleted={ () => { refetch(); setTalentDeleted(true); } }
                            />
                        }   
                    </Col>
                    <Col>
                        <CSVLink data={CSVdata} filename={'タレント.csv'}>
                            <Button icon={<ExportOutlined />}>
                                CSV 出力
                            </Button>
                        </CSVLink>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>;

    return <Switch>
        <Space style={{ width: '100%' }} direction='vertical'>
            <Row>
                <Col span={24}>
                    <Card title={<Typography.Title level={3} style = {{ background: '#fff' }}>タレント一覧</Typography.Title>}>
                        <Route exact path={path}>
                            <Tabs onChange={(activeTab) => setActiveTab(activeTab) } defaultActiveKey="list">
                                <Tabs.TabPane tab="担当リスト" key="list">
                                    <Table
                                        scroll={{
                                            y: 500,
                                          }}
                                        columns={columns}
                                        dataSource={ownTalents}
                                        rowSelection={{ type: 'checkbox', ...rowSelection }}
                                        title={() => TableHeader}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="全てのリスト" key="all">
                                    <Table
                                        scroll={{
                                            y: 500,
                                          }}
                                        columns={columns}
                                        dataSource={rows}
                                        rowSelection={{ type: 'checkbox', ...rowSelection }}
                                        title={() => TableHeader}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                            { showAddTalentModal &&
                                <AddTalentModal 
                                    handleCompleted={() => { refetch(); message.success('全てのリストに追加しました。', 4); }}
                                    visible={showAddTalentModal}
                                    hideModal={hideModal}
                                />
                            }
                        </Route>
                    </Card>
                </Col>
            </Row>
        </Space>
    </Switch>;
};

export default TalentsTable;
